import React, { useEffect } from "react";
import api from "api";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "components/styled";
import styled from "styled-components";
import { Typography } from "@mui/material";
import colors from "styles/colors";

const UserGoalSection = styled.div`
  padding-left: 15%;
  padding-right: 15%;
  width: auto;
`;

const HubspotFormContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const UserFormContainer = styled(Container)`
  justify-content: flex-start;
  gap: 50px;
`;

const UserGoalsTitle = styled(Typography).attrs({
  variant: "mk_serif_h2",
})`
  color: ${colors.NUDE[8]};
  text-align: center;
`;

const UserGoalsSubtitle = styled(Typography).attrs({
  variant: "mk_body2",
})`
  color: ${colors.CORAL[9]};
`;

const SkipLink = styled.a`
  display: block;
  text-align: right;
  margin-top: 100px;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.CORAL[5]};
`;

const UserGoalsRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleFormSubmission = async () => {
    try {
      await api.put("/users/update_user_goals", {
        email: location.state.email,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error updating column: ", error);
    } finally {
      // window.location.href = '/account_settings';

      window.location.href = "/community";
    }
  };

  useEffect(() => {
    if (!location.state?.email) {
      navigate("/404");
    }
  }, [location, navigate]);

  useEffect(() => {
    const loadHubspotForm = () => {
      // @ts-ignore
      if (window.hbspt && location.state?.email) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID || "",
          formId: process.env.REACT_APP_HUBSPOT_USER_GOALS_FORM_ID || "",
          target: "#userGoalsForm",
          onFormReady: () => {
            const frame = document.querySelector(
              '[id^="hs-form-iframe-"]'
            ) as HTMLIFrameElement | null;
            if (frame?.contentDocument) {
              const inputElement = frame.contentDocument.getElementById(
                "email-f6e3a47a-4ae8-49e9-9362-85a7d290754f"
              ) as HTMLInputElement | null;
              const formContainer = frame.contentDocument.querySelector(
                ".hs_email.hs-email.hs-fieldtype-text.field.hs-form-field"
              ) as HTMLElement | null;
              if (inputElement && formContainer) {
                inputElement.value = location.state.email;
                inputElement.dispatchEvent(
                  new Event("input", { bubbles: true })
                );
                inputElement.dispatchEvent(
                  new Event("change", { bubbles: true })
                );
                formContainer.style.display = "none";
              } else {
                // eslint-disable-next-line
                console.log("Input ID is not found inside the iframe.");
              }
            }
          },
          onFormSubmitted: handleFormSubmission,
        });
      }
    };
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", loadHubspotForm);
    return () => {
      script.removeEventListener("load", loadHubspotForm);
    };
  }, [location.state, navigate]);

  const handleSkip = async () => {
    await handleFormSubmission();
  };

  return (
    <Container direction="column">
      <UserGoalSection>
        <UserFormContainer>
          <UserGoalsTitle>Welcome to Health in Her HUE!</UserGoalsTitle>
          <UserGoalsSubtitle>
            At Health in Her HUE, we believe in guiding every woman of color in
            taking charge of her health journey. Our community fosters support,
            understanding, and a space to connect over shared experiences. When
            you join Health in Her HUE, you&apos;re not just a member;
            you&apos;re a vital part of a movement toward health equity and
            wellness.
          </UserGoalsSubtitle>
        </UserFormContainer>
        <HubspotFormContainer id="userGoalsForm" />
        <SkipLink onClick={handleSkip}>Skip</SkipLink>
      </UserGoalSection>
    </Container>
  );
};

export default UserGoalsRoute;
